





































































































import { Component, Mixins } from 'vue-property-decorator';
import { ReportChartRingComponent, ReportChartHistogramComponent } from '@/mixins/report-chart-base';
import BAIndexService from './service/ba-index';
import { BAFacilityRunTimeQueryModel } from './model/ba-facility-run-time-model';
import { BAFacilityFaultQueryModel } from './model/ba-facility-fault-model';

@Component
export default class BAIndexComponent extends Mixins(ReportChartRingComponent, ReportChartHistogramComponent) {
    headerCardList: Array<any> = [
        { title: '总设备数', count: null, unit: '台', loading: true, icon: '/img/device.png' },
        { title: '运行设备数', count: null, unit: '个', loading: true, icon: '/img/device-online.png' },
        { title: '故障设备数', count: null, unit: '个', loading: true, icon: '/img/fault.png' },
        { title: '正在告警', count: null, unit: '条', loading: true, icon: '/img/alarm.png' },
        { title: '今天未处理告警', count: null, unit: '条', loading: true, icon: '/img/alarm-unhandle.png' }
    ];

    facilityRunTimeCount: any = {};
    facilityRunTimeCountLoading: boolean = true;

    waterPressData: any = null;
    waterPressDataLoading: boolean = false;

    facilityStatusData: any = null;
    facilityStatusDataLoading: boolean = true;

    facilityFaultData: any = {};
    facilityFaultDataLoading: boolean = true;

    alarmStatusCountData: any = null;
    alarmStatusCountDataLoading: boolean = true;

    last7DayAlarmData: any = {};
    last7DayAlarmDataLoading: boolean = true;

    alarmGradeCountData: any = null;
    alarmGradeCountDataLoading: boolean = true;

    last7DayTop5AlarmData: any = null;
    last7DayTop5AlarmDataLoading: boolean = true;

    created() {
        this.initData();
    }

    initData() {
        BAIndexService.getFacilityCount().then(res => {
            this.headerCardList[0].count = res.totalCount;
            this.headerCardList[1].count = res.runingCount;
            this.headerCardList[2].count = res.faultCount;
            this.facilityStatusData = {
                columns: ['name', '数量'],
                rows: [
                    { name: '停机', '数量': res.stopCount || 0 },
                    { name: '运行', '数量': res.runingCount || 0 }
                ]
            };
        }).finally(() => {
            this.headerCardList[0].loading = false;
            this.headerCardList[1].loading = false;
            this.headerCardList[2].loading = false;
            this.facilityStatusDataLoading = false;
        });
        BAIndexService.getAlarmActiveCount().then(res => {
            this.headerCardList[3].count = res.activeCount;
            this.headerCardList[4].count = res.historyCount;
        }).finally(() => {
            this.headerCardList[3].loading = false;
            this.headerCardList[4].loading = false;
        });

        BAIndexService.getFacilityRunTime(new BAFacilityRunTimeQueryModel().toService()).then(res => {
            this.facilityRunTimeCount = res;
        }).finally(() => this.facilityRunTimeCountLoading = false);

        BAIndexService.getFacilityFaultCount(new BAFacilityFaultQueryModel().toService()).then(res => {
            this.facilityFaultData = res;
        }).finally(() => this.facilityFaultDataLoading = false);

        BAIndexService.getLast7DayTop5AlarmCount({ limit: 5 }).then(res => {
            this.last7DayTop5AlarmData = res;
        }).finally(() => this.last7DayTop5AlarmDataLoading = false);

        BAIndexService.getAlarmStatusCount().then(res => {
            this.alarmStatusCountData = res;
        }).finally(() => this.alarmStatusCountDataLoading = false);

        BAIndexService.getLast7DayAlarmCount().then(res => {
            this.last7DayAlarmData = res;
        }).finally(() => this.last7DayAlarmDataLoading = false);

        BAIndexService.getAlarmGradeCount().then(res => {
            this.alarmGradeCountData = res;
        }).finally(() => this.alarmGradeCountDataLoading = false);
    }

    get ParkingGateRankYAxis() {
        return {
            type: 'category',
            data: _.map(_.get(this.last7DayTop5AlarmData, 'rows'), item => item.name),
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            splitLine: {
                show: false
            }
        };
    }
}
